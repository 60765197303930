import request, { gql } from "graphql-request";
import { GetStaticPropsContext } from "next";
import Head from "next/head";
import Link from "next/link";

import { Container } from "@shared/elements";

import bundleMessages from "src/utils/bundleMessages";

export default function Index({ calls }) {
  return (
    <>
      <Head>
        <title key="title">Picter Contests</title>
      </Head>
      <Container className="mt-14">
        <div className="grid grid-cols-1 py-5">
          {calls.map((call, i) => (
            <Link
              data-testid={call.slug}
              href={call.slug}
              shallow
              key={i}
              className="w-full"
            >
              {call.slug}
            </Link>
          ))}
        </div>
      </Container>
    </>
  );
}

export async function getStaticProps({ locale }: GetStaticPropsContext) {
  const messages = await bundleMessages(locale, ["pages.index"]);

  const query = gql`
    {
      calls {
        id
        slug
        callPages {
          id
        }
      }
    }
  `;

  const { calls } = await request(
    `${process.env.NEXT_PUBLIC_API_URL}/graphql`,
    query,
  );

  return {
    props: {
      calls: calls.filter((call) => call?.callPages?.length > 0),
      messages,
    },
    // Next.js will attempt to re-generate the page:
    // - When a request comes in
    // - At most once every 60 seconds
    revalidate: 60, // In seconds
  };
}
